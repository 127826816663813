import firebase from "firebase/app";
import "firebase/auth";
import firebaseConfig from "../config/firebase.js";
  

export function googleSignin(promiseFunction){

    if (!firebase.apps.length) {
          firebase.initializeApp(firebaseConfig);
          
        } else {
          firebase.app(); // if already initialized, use that one
        }
    
        var provider = new firebase.auth.GoogleAuthProvider();
        provider.setCustomParameters({
      prompt: 'select_account'
    });
        //provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
    firebase.auth()
      .signInWithPopup(provider)
      .then((result) => {
        /** @type {firebase.auth.OAuthCredential} */
        //var credential = result.credential;
    
        // This gives you a Google Access Token. You can use it to access the Google API.
        //var token = credential.accessToken;
        // The signed-in user info.
        var user = result.user;
        // ...
        promiseFunction(user.displayName);
    
      }).catch((error) => {
        // Handle Errors here.
        //var errorCode = error.code;
        //var errorMessage = error.message;
        // The email of the user's account used.
        //var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        //var credential = error.credential;
        console.log("There was google auth error");
        console.dir(error);
        // ...
      });
        }

