<template>
  <div class="card">
    <div class="card-header">
      <bs-icon v-if="headerImage" :image="headerImage"></bs-icon>
      {{ title }}
      <a href="#" v-if="showMinimise" @click.prevent="$emit('minimise');" class="bsMinimise">
      <bs-icon image="file-minus" width="20" height="20"></bs-icon>
      </a>
    </div>
    <div class="card-body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
    emits: ['minimise'],

  props: ["title","headerImage","showMinimise"],
};
</script>

<style  scoped>

.bsMinimise{
 position: absolute;
  right: 10px;
}



</style>
