<template>
  <div class="form-floating mb-3">
    <input
      :type="type"
      class="form-control"
      :id="id"
      :style="errorStyle"
      :placeholder="placeholder"
      @blur="$emit('blur')"
      @focus="$emit('focus')"
      :value="modelValue" 
    @input="$emit('update:modelValue', $event.target.value)"
    />
    <label :for="id">{{ label }}</label>
  </div>
</template>

<script>
import { computed } from "vue";
export default {
  props: ["type", "label", "id", "placeholder", "error","modelValue"],
  setup(props) {
    
    const errorStyle = computed(() => {
      return props.error ? "border-color:red" : "";
    });


    return { errorStyle };
  },
};
</script>

<style scoped></style>
