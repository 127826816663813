<template>
  <!-- <bs-hf-card
    :title="listInfo.title"
    :headerImage="listInfo.image"
    showMinimise="true"
    @minimise="$emit('minimise')"
  > -->
  <div class="container task-list-container card">
  <nav class="navbar navbar-light bg-light">
  <div class="container-fluid">
    <a class="navbar-brand" href="#"><bs-icon :image="listInfo.image" /> {{listInfo.title}}</a>
<a href="#" @click.prevent="$emit('minimise');" class="d-flex">
      <bs-icon image="file-minus" width="20" height="20"></bs-icon>
      </a>
  </div>
</nav>
<div
              data-bs-spy="scroll"
              data-bs-target="#navbar-example"
              data-bs-offset="0"
              class="scrollspy-example"
              tabindex="0"
            >
    <ul>
      <li v-for="task in taskList" :key="task.id">
        <!--         <input
          class="form-check-input"
          type="checkbox"
          value=""
          id="flexCheckDefault"
          @click="doneTask(task.id)"
        />
        {{ task.title }}
        <a href="#" @click.prevent="addTaskToProject(task.id)"><bs-icon
          v-if="projectId && projectId != '' && taskType == 'inbox'"
          image="arrow-right-circle-fill"
                  /></a>
 -->
        <task-tile
          :task="task"
          @doneTask="doneTask"
          :activeProject="projectId"
          :parentList="taskType"
        ></task-tile>
      </li>
    </ul>
</div>
    <p v-if="taskType==='todo'">{{ completedTaskCount }} tasks completed today</p>
  <!-- </bs-hf-card> -->
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import TaskTile from "./TaskTile.vue";
import TaskHelper from "../hooks/taskhelper.js";
import BsIcon from './ui/bootstrap/BsIcon.vue';

export default {
  components: { TaskTile, BsIcon },
  emits: ["minimise"],
  props: ["taskType", "projectId"],
  setup(props) {
    const store = useStore();
    var d = new Date();
    d.setHours(0,0,0,0);
    const completedTaskCount = computed(() => {
      return store.getters["task/tasks"].filter((task) => {
        return task.archived && new Date(task.archived) > d;
    
      
    }).length;
    });

    const taskList = computed(() => {
    const tasks = store.getters["task/tasks"].filter((task) => {
        return !task.archive;
      });

      if (props.taskType === "inbox") {
        return tasks.filter((task) => {
          return (
            (!task.todoList || task.todoList === "") &&
            (!task.projectId || task.projectId === "") &&
            !TaskHelper.isSnoozed(task) &&
            !TaskHelper.isFollowup(task)
          );
        });
      }
      if (props.taskType === "todo") {
        return tasks.filter((task) => {
          return task.todoList && task.todoList != "";
        });
      }
      if (props.taskType === "project") {
        return tasks.filter((task) => {
          return (
            task.projectId === props.projectId &&
            !TaskHelper.isSnoozed(task) &&
            !TaskHelper.isFollowup(task)
          );
        });
      }
      if (props.taskType === "snoozed") {
        return tasks.filter((task) => {
          return TaskHelper.isSnoozed(task);
        });
      }
      if (props.taskType === "followup") {
        return tasks.filter((task) => {
          return TaskHelper.isFollowup(task);
        });
      }
      return [];
    });

    const listInfo = computed(() => {
      switch (props.taskType) {
        case "inbox":
          return { image: "inboxes-fill", title: "In-tray" };
        case "todo":
          return { image: "clipboard", title: "To-do List" };
        case "followup":
          return { image: "chat-quote", title: "Follow up" };
        case "snoozed":
          return { image: "alarm", title: "Snoozed" };
        case "project":
          return {
            image: "folder",
            title: store.getters["task/projects"].filter((project) => {
              return project.id === props.projectId;
            })[0].title,
          };

        default:
          break;
      }
      return "";
    });

    function doneTask(id) {
      store.dispatch("task/archiveTask", id);
    }
    return { taskList, doneTask, listInfo,completedTaskCount };
  },
};
</script>

<style scoped>
.task-list-container {
  width: 290px;
  margin-bottom: 30px;
  background-color: white;
}

ul {
  list-style-type: none;
  display: contents;
}

ul > li {
  padding: 0;
  margin: 0;
}

body {
  position: relative;
}
.scrollspy-example{position:relative;height:400px;margin-top:.5rem;overflow:auto}.scrollspy-example-2{position:relative;height:350px;overflow:auto}
</style>
