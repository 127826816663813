<template>
  <div style="height: 100vh;">
    <the-header v-if="!isAuthenticated"></the-header>
    <router-view></router-view>
  </div>
  
</template>

<script>
import TheHeader from "./components/ui/TheHeader.vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

export default {
  components: { TheHeader },
  name: "App",
  computed: {
    didAutoLogout() {
      return this.$store.getters["auth/didLogout"];
    },
    isAuthenticated() {
      return this.$store.getters["auth/isAuthenticated"];
    },
  },
  watch: {
    didAutoLogout(newValue, oldValue) {
      if (newValue && newValue != oldValue) {
        this.$router.replace("/auth");
      }
    },
  },
};
</script>

<style>
/*
body, html {
    height: 100%;
    position: relative;
    margin:0;
}   


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
*/
</style>
