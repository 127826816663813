<template>
  <bs-modal
    :showModal="authData.error"
    @modalClosed="authData.error = false"
    title="There was an error"
  >
    {{ authData.errorMessage }}</bs-modal
  >
  <div class="container">
    <bs-hf-card title="Login" style="margin-bottom: 30px;">
        <a href="#" @click.prevent="signupGoogle"><img src="/btn_google_signin_light_focus_web.png" /></a>
    </bs-hf-card>

    <bs-hf-card title="Login with Email">
      <div v-if="authData.loading" style="text-align: center">
        <bs-spinner style="text-align: center"></bs-spinner>
      </div>
      <form v-else>
        <bs-float-input
          v-model="authData.email"
          type="email"
          id="email"
          placeholder="name@example.com"
          label="Email Address"
          :error="emailError"
          @focus="authData.emailValid = true"
        ></bs-float-input>
        <bs-float-input
          v-model="authData.password"
          type="password"
          id="password"
          placeholder=""
          label="Password"
          :error="passwordError"
        ></bs-float-input>
        <bs-button @click="login" buttonStyle="primary">Login</bs-button>
      </form>
    </bs-hf-card>
  </div>
</template>

<script>
import { computed, reactive } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import {googleSignin } from '../../hooks/authproviders.js'

export default {
  setup() {
    const store = useStore();
    const router = useRouter();

    const authData = reactive({
      email: "",
      password: "",
      emailValid: true,
      passwordValid: true,
      loading: false,
      error: false,
      errorMessage: "",
    });

    function signupGoogle(){
      googleSignin((name)=>{
            store.dispatch('user/setName',name);
            //console.log("Google user is: ".auth.currentUser.email);
            router.replace("/project?id=1");

      });
    }


    async function login() {
      if (validateForm()) {
        authData.loading = true;
        try {
          authData.router = router;
          store.dispatch("auth/login", authData);
        } catch (error) {
          authData.error = true;
          authData.errorMessage =
            error.message || "There was an error please try again";
          //console.log(error);
        }
        //authData.loading = false;
        //router.replace("/project");
        //console.log(store);
      } else {
        console.log("Validating Error");
      }
    }

    function validateForm() {
      authData.emailValid = true;
      authData.passwordValid = true;
      var valid = true;
      if (!authData.email.includes("@")) {
        authData.emailValid = false;
        valid = false;
      }
      if (authData.password.length < 6) {
        authData.passwordValid = false;
        valid = false;
      }
      return valid;
    }

    const emailError = computed(() => {
      return !authData.emailValid;
    });

    const passwordError = computed(() => {
      return !authData.passwordValid;
    });

    return { login, authData, validateForm, emailError, passwordError,signupGoogle };
  },
};
</script>
