<template>
  <bs-modal
    @modalMounted="getModal"
    :showModal="showModal"
    :title="task.title + ' - Actions'"
    :showSave="false"
    :closeText="'Cancel'"
    modalSize="modal-lg"
  >
    <div class="container">
      <div class="row g-2">
        <div class="col-12">
          <div class="p-3 border bg-light">
            <strong>Send this task to your follow up list</strong>
            <p>
              Set a person you are waiting on and/or a place you need to be to
              proceed with this task. For example if you have delegated it to
              someone or you need to be at the hardware store to buy something
            </p>
            <form @submit.prevent="forwardTask">
              <bs-float-input
                v-model="followupPerson"
                type="text"
                label="Person to wait for (e.g Joan from accounts)"
              ></bs-float-input>
              <bs-float-input
                v-model="followupPlace"
                type="text"
                label="Place you need to be (e.g. Melbourne Office)"
              ></bs-float-input>
              <bs-button @click="forwardTask">Set task for follow-up</bs-button>
            </form>
          </div>
        </div>
        <div class="col-12">
          <div class="p-3 border bg-light">
            <strong>Snooze this task</strong>
            <p>
              Make this task disappear (although it will still appear in
              searches) unitl a set date
            </p>
            <form @submit.prevent="snoozeTask">
              <p><input type="datetime-local" v-model="snoozeDate" /></p>
              <p>
                <bs-button @click="snoozeTask"> Snooze this task</bs-button>
              </p>
            </form>
          </div>
        </div>
        <div class="col-12">
          <div class="p-3 border bg-light">
            <strong>Add a note</strong>
            <p>
              Log some comments for this task
            </p>
            <form @submit.prevent="snoozeTask">
              <div class="form-floating" style="margin-bottom: 12px;">
                <textarea v-model="taskNote"
                  class="form-control"
                  placeholder="Leave a comment here"
                  style="height: 150px"
                ></textarea>
                <label for="floatingTextarea2">Comments</label>
              </div>
              <p><bs-button @click="saveNote"> Save Note</bs-button></p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </bs-modal>
</template>

<script>
import BsButton from "../ui/bootstrap/BsButton.vue";
import { ref } from "vue";
import { useStore } from "vuex";

export default {
  components: { BsButton },
  props: ["showModal", "task"],
  emits: ["modalMounted"],
  setup(props, context) {
    const store = useStore();
    //const modalVisible = ref(props.showModal);
    //const followup = reference({followupPerson: '', followupPlace: ''});
    const followupPerson = ref(props.task.followupPerson);
    const followupPlace = ref(props.task.followupPlace);
    const snoozeDate = ref(new Date(props.task.snoozed));
    const taskNote = ref('');
    var actionModal = null;

    //const showModalVisible = computed(() =>{console.log(props.showModal.show); return props.showModal; });
    const showModalVisible = ref(false);
    function snoozeTask() {
      const d = new Date(snoozeDate.value);
      store.dispatch("task/snoozeTask", {
        id: props.task.id,
        snoozed: d.toISOString(),
      });
      actionModal.hide();
    }

    function forwardTask() {
      store.dispatch("task/setFollowup", {
        id: props.task.id,
        followupPerson: followupPerson.value,
        followupPlace: followupPlace.value,
      });
      actionModal.hide();
    }

    function saveNote(){
      store.dispatch("task/addNote", {
        id: props.task.id,
        note: taskNote.value,});
      actionModal.hide();
      taskNote.value ='';

    }

    function getModal(modal) {
      context.emit("modalMounted", modal);
      actionModal = modal;
    }

    return {
      snoozeTask,
      forwardTask,
      followupPlace,
      followupPerson,
      snoozeDate,
      showModalVisible,
      getModal,
      saveNote,
      taskNote
    };
  },
};
</script>

<style scoped>
.modal-dialog {
  width: 800px;
  background-color: crimson;
}
</style>
