import { database,auth } from '../../firebase.js'

import { UpdateTaskDb } from "../../hooks/taskdb.js";

//Tasks VueX

export default {
  namespaced: true,
  state() {
    return {
      contexts: [],
      projects: [],
      tasks: [],
    };
  },
  mutations: {
    addContext(state, payload) {
      state.contexts.push(payload);
    },
    addProject(state, payload) {
      state.projects.push(payload);
    },
    addTask(state, payload) {
      state.tasks.push(payload);
    },
    updateContext(state, payload) {
      for (var i = 0; i < state.context.length; ++i) {
        if (state.contexts[i].id === payload.id) {
          Object.assign(state.contexts[i], payload);
          return;
        }
      }
    },
    updateProject(state, payload) {
      for (var i = 0; i < state.projects.length; ++i) {
        if (state.projects[i].id === payload.id) {
          Object.assign(state.projects[i], payload);
          return;
        }
      }
    },
    updateTask(state, payload) {
      console.log("looking for ID: " + payload.id);
      for (var i = 0; i < state.tasks.length; ++i) {
        if (state.tasks[i].id === payload.id) {
          Object.assign(state.tasks[i], payload);
          return;
        }
      }
    },
    clearContexts(state) {
      state.contexts = [];
    },
    clearProjects(state) {
      state.projects = [];
    },
    clearTasks(state) {
      state.tasks = [];
    },
  },
  getters: {
    contexts(state) {
      return state.contexts;
    },
    projects(state) {
      return state.projects;
    },
    tasks(state) {
      return state.tasks;
    },
  },
  actions: {
    initialiseApp(context) {
      context.commit("clearTasks");
      context.commit("clearContexts");
      context.commit("clearProjects");

      if (!auth.currentUser) {
        return;
      }
          const loggedUser = auth.currentUser;
          //Contexts
          database
            .ref("context/" + loggedUser.uid)
            .on("child_added", (snapshot) => {
              const sw_context = snapshot.val();
              sw_context["id"] = snapshot.key;
              context.commit("addContext", sw_context);
            });

          database
            .ref("context/" + loggedUser.uid)
            .on("child_changed", (snapshot) => {
              const sw_context = snapshot.val();
              sw_context["id"] = snapshot.key;
              context.commit("updateContext", sw_context);
            });
          //Projects
          database
            .ref("project/" + loggedUser.uid)
            .on("child_added", (snapshot) => {
              const project = snapshot.val();
              project["id"] = snapshot.key;
              context.commit("addProject", project);
            });

          database
            .ref("project/" + loggedUser.uid)
            .on("child_changed", (snapshot) => {
              const project = snapshot.val();
              project["id"] = snapshot.key;
              context.commit("updateProject", project);
            });
          //Tasks
          database
            .ref("task/" + loggedUser.uid)
            .on("child_added", (snapshot) => {
              const task = snapshot.val();
              task["id"] = snapshot.key;
              context.commit("addTask", task);
            });

          database
            .ref("task/" + loggedUser.uid)
            .on("child_changed", (snapshot) => {
              const task = snapshot.val();
              task["id"] = snapshot.key;
              context.commit("updateTask", task);
            });
    },
    addTask(context, payload) {

      const loggedUser = auth.currentUser;

      payload["created"] = new Date().toISOString();
      database.ref("task/" + loggedUser.uid).push(payload);
    },
    addContext(context, payload) {

      const loggedUser = auth.currentUser;

      payload["created"] = new Date().toISOString();
      database.ref("context/" + loggedUser.uid).push(payload);
    },
    addProject(context, payload) {
      const loggedUser = auth.currentUser;

      payload["created"] = new Date().toISOString();
      database.ref("project/" + loggedUser.uid).push(payload);
    },
    addTaskToTodo(context, payload) {
      UpdateTaskDb(payload.taskId, { todoList: new Date().toISOString() });
    },
    removeTaskToTodo(context, payload) {
      UpdateTaskDb(payload.taskId, { todoList: "" });
    },
    addTaskToProject(context, payload) {
      const contextId = context.getters.projects.filter((project) => {
        return project.id === payload.projectId;
      })[0].context;
      UpdateTaskDb(payload.taskId, {
        projectId: payload.projectId,
        contextId: contextId,
      });
    },
    removeTaskFromProject(context, payload) {
      UpdateTaskDb(payload.taskId, { projectId: "" });
    },
    archiveTask(context, payload) {
      UpdateTaskDb(payload, { archive: true, archived: new Date().toISOString() });
    },
    snoozeTask(context, payload) {
      UpdateTaskDb(payload.id, { snoozed: payload.snoozed, todoList: null });
    },
    setFollowup(context, payload) {
      UpdateTaskDb(payload.id, {
        followupPerson: payload.followupPerson ? payload.followupPerson : "",
        followupPlace: payload.followupPlace ? payload.followupPlace : "",
        followupSet: new Date().toISOString(),
        todoList: null,
      });
    },
    startTask(context, payload) {
      //Find any started tasks and stop them first
      const startedTasks = context.getters['tasks'].filter(task => {return task.started && task.started!=''});
      for(const startedTask of startedTasks){
        context.dispatch('stopTask',{taskId: startedTask.id});
      }
      UpdateTaskDb(payload.taskId, { started: new Date().toISOString() });
    },
    stopTask(context, payload) {
      const task = context.getters['tasks'].filter(task => {return task.id === payload.taskId})[0];

    database
      .ref("task/" + auth.currentUser.uid + "/" + task.id+"/timelog")
      .push({start: task.started,end: new Date().toISOString()});
      UpdateTaskDb(payload.taskId, { started: '' });
    },
    addNote(context, payload) {

    database
      .ref("task/" + auth.currentUser.uid + "/" + payload.id+"/notes")
      .push({note: payload.note, created: new Date().toISOString(0)});
    },
    updateTask(context,payload){
      const taskId =payload.id
      delete payload.id
      UpdateTaskDb(taskId, payload);
    },

    
  },
};
