import {auth} from '../../firebase.js'
//import { useRouter } from 'vue-router';

export default {
  initialiseAuthentication(context,payload) {
    const user = payload;
/*     if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
      
    } else {
      firebase.app(); // if already initialized, use that one
    }
 */    context.commit("setUser", localStorage.getItem("user"));

    // firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    // firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        context.commit("setUser", user);
        localStorage.setItem("user", user);
        context.commit("didLogout", false);
        context.commit("hasLoggedIn", true);
        context.dispatch('user/loadUser', null,{root:true});
        context.dispatch('task/initialiseApp', null,{root:true});
      } else {
        localStorage.removeItem("user");
        if (context.getters.hasLoggedIn) {
          context.commit("didLogout", true);
        }
        context.commit("clearUser", user);
        // No user is signed in.
      }
  //  });
  },
  logout(context) {
    auth.signOut();
    context.commit("didLogout", true);
  },
  getCurrentUser(){
    return auth.currentUser;
   },
  async signup(context, payload) {
    
    try {
      const userCredential = await auth
        .createUserWithEmailAndPassword(payload.email, payload.password);
        context.commit("setUser", userCredential.user);
        auth.currentUser.sendEmailVerification();


      } catch (error) {
      throw new Error(error.message || "Can't add user");
    }
  },
  async login(context, payload) {
    const router = payload.router;
    //router.replace("/home");
    try {
      const userCredential = await auth
        .signInWithEmailAndPassword(payload.email, payload.password);
        await context.dispatch("initialiseAuthentication");
        router.replace("/project");
        console.log("uid is "+ userCredential.user.uid)
        /*
        const user = {
        localId: userCredential.user.uid,
        token: userCredential.user.token,
      };
      console.log("Logging in with email/password");
      console.log("uid is "+ userCredential.user.uid)
      console.log("user is "+ user)
      console.log("Vuex redirecting to project");
      router.replace("/home");
*/      
      //return user;
    } catch (error) {
      throw new Error(error.message || "Can't add user");
    }
    
  },
};
