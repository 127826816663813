import {auth, database} from '../../firebase.js';
//User Vuex

export default {
  namespaced: true,
  state() {
    return {
      name: "",
      created: new Date().toISOString(),
    };
  },
  actions: {
    loadUser(context){
          if(!auth.currentUser){
            return;
        }
        const path = 'user/' + auth.currentUser.uid;
        auth
        .updateCurrentUser(auth.currentUser)
        .then(() => {
        database.ref(path).get().then(response=>{
            const user = response.val();
            context.commit('setName',user.name);

        }).catch(error => { console.log("error registering user callback: "+path+"----"+error)});
      });
    },
    saveUser(context) {
        //const loggedUser = context.rootGetters['auth/getUser'];
        const user = {
            name: context.getters.name,
            created: context.getters.created,
        };

        database.ref('user/'+auth.currentUser.uid).set(user);

    },
    setName(context,payload){
        context.commit('setName',payload);
        context.dispatch('saveUser');
    }
  },
  mutations: {
    setName(state,payload){
        state.name = payload;
    }
  },
  getters: {
    name(state) {
      return state.name;
    },
    created(state){
        return state.created;
    },
  },
};
