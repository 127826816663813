<template>
    
<main>
  <div class="container py-4">
    

    <div class="p-5 mb-4 bg-light rounded-3">
      <div class="container-fluid py-5">
        <h1 class="display-5 fw-bold">Simple and Powerful Task Management</h1>
        <p class="col-md-8 fs-4">Get your tasks in order.</p>
        <button class="btn btn-primary btn-lg" type="button">Watch the video</button>
      </div>
    </div>

    <div class="row align-items-md-stretch">
      <div class="col-md-6">
        <div class="h-100 p-5 text-white bg-dark rounded-3">
          <h2>Full suite website desktop</h2>
          <p>Manage your day and projects on the desktop.</p>
          <button class="btn btn-outline-light" type="button">Login / Signup</button>
        </div>
      </div>
      <div class="col-md-6">
        <div class="h-100 p-5 bg-light border rounded-3">
          <h2>Keep track of tasks on the go</h2>
          <p>Quickly jot notes and tasks and view your to-do list on the go</p>
          <button class="btn btn-outline-secondary" type="button">Download from the App Store</button>
        </div>
      </div>
    </div>

    <footer class="pt-3 mt-4 text-muted border-top">
      &copy; 2021
    </footer>
  </div>
</main>
</template>
