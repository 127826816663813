
import { createApp } from "vue";
import App from "./App.vue";

import { auth } from "./firebase.js";

import router from "./router.js";
import store from "./store/index.js";

import BsButton from "./components/ui/bootstrap/BsButton.vue";
import BsFloatInput from "./components/ui/bootstrap/BsFloatInput.vue";
import BsHfCard from "./components/ui/bootstrap/BsHfCard.vue";
import BsSpinner from "./components/ui/bootstrap/BsSpinner.vue";
import BsModal from "./components/ui/bootstrap/BsModal.vue";
import BsIcon from "./components/ui/bootstrap/BsIcon.vue";

//import firebase from 'firebase'

/* var config = {
    apiKey: "apiKey",
    //authDomain: "projectId.firebaseapp.com",
    // For databases not in the us-central1 location, databaseURL will be of the
    // form https://[databaseName].[region].firebasedatabase.app.
    // For example, https://your-database-123.europe-west1.firebasedatabase.app
    databaseURL: "https://swift2do-default-rtdb.asia-southeast1.firebasedatabase.app/",
    //storageBucket: "bucket.appspot.com"
  };
  firebase.initializeApp(config);

  // Get a reference to the database service
  var database = firebase.database();
  //const d = new Date();
  database.ref('tasks/user1').set([{title: 'task1',createdDate: new Date().toISOString(),description: 'this is a test'},{title: 'task2'},{title: 'task3'},{title: 'task4'}]);
  database.ref('projects/user1').set([{title: 'task1',createdDate: new Date().toISOString(),description: 'this is a test'},{title: 'task2'},{title: 'task3'},{title: 'task4'}]);
 */

store.dispatch("auth/initialiseAuthentication");

let app;
auth.onAuthStateChanged(function(user) {
  store.dispatch("auth/initialiseAuthentication", user);
  if (!app) {
    app = createApp(App);
    app.config.devtools = true;
    app.config.performance = true;
    app.use(router);
    app.use(store);
    app.component("bs-button", BsButton);
    app.component("bs-float-input", BsFloatInput);
    app.component("bs-hf-card", BsHfCard);
    app.component("bs-spinner", BsSpinner);
    app.component("bs-modal", BsModal);
    app.component("bs-icon", BsIcon);
    app.mount("#app");
  }
});

/* const app = createApp(App);

app.use(router);
app.use(store);

app.mount('#app')
 */
