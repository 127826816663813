<template>
  <div class="container">
    <bs-hf-card title="Please Verify email" style="margin-bottom: 30px;">
      <p>Please check {{ emailAddress }} for the verification link</p>
      <p><bs-button @click="recheckLink">I have clicked the email link</bs-button></p>
      <p><bs-button class="btn btn-secondary" @click="sendVerify">Resend the link</bs-button></p>
      <p><bs-button class="btn btn-secondary" @click="logout">Logout</bs-button></p>
    </bs-hf-card>
  </div>
</template>

<script>
import { auth } from "../../firebase.js";
import { computed, watch } from "vue";
//import { useStore } from 'vuex';
import { useRouter } from "vue-router";

export default {
  setup() {
    const router = useRouter();

    const emailAddress = computed(() => {
      return auth.currentUser.email; //store.dispatch('auth/getCurrentUser').email;
    });

    const emailVerified = computed(() => {
      return auth.currentUser.emailVerified; //store.dispatch('auth/getCurrentUser').email;
    });

    function recheckLink(){
            const user = auth.currentUser;
                user.reload();
                          router.replace("/project");

    }

    watch(
      () => auth.currentUser.emailVerified,
      (verified) => {
        if (verified) {
          router.replace("/project");
        }
      }
    );

    function sendVerify() {}

    function logout() {
      auth.signOut();
      router.replace("/auth");
    }

    return { logout, sendVerify, emailAddress, emailVerified,recheckLink };
  },
};
</script>
