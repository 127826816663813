import firebase from "firebase/app";
import "firebase/auth";
import firebaseConfig from "../config/firebase.js";
  

export function UpdateTaskDb(taskId,updateObject){
    if (!firebase.apps.length) {
          firebase.initializeApp(firebaseConfig);
          
        } else {
          firebase.app(); // if already initialized, use that one
        }
        console.log("task/" + firebase.auth().currentUser.uid + "/" + taskId);
        var database = firebase.database();
      database
        .ref("task/" + firebase.auth().currentUser.uid + "/" + taskId)
        .update(updateObject);
    }


