<template>
    Test Page
</template>

<script>
export default {
    setup() {
        console.log("test Setup");
    },mounted(){
        console.log("test mounted");
    }
}
</script>