export default{

    setUser(state,payload){
        state.user = payload;

    },
    clearUser(state){
        state.user = null;
    },
    didLogout(state,payload){
        state.didLogout = payload;
    },
    hasLoggedIn(state,payload){
        state.hasLoggedIn = payload;
    },
    emailVerified(state,payload){
        state.emailVerified = payload;
    },

}