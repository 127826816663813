<template>
  <bs-modal
    @modalMounted="getModal"
    :showModal="showModal"
    :title="taskDetails.title + ' - Details and Settings'"
    :showSave="false"
    :closeText="'Cancel'"
    modalSize="modal-lg"
  >
    <div class="container">
      <div class="row g-2">
        <div class="col-12">
          <div class="p-3 border bg-light">
            <strong>Settings for this task</strong>
            <div class="row g-2">
              <div class="col-md">
                            <bs-float-input
                v-model="taskDetails.title"
                type="text"
                label="Task Title"
              ></bs-float-input>
              </div>
              <div class="col-md">
                            <bs-float-input
                v-model="taskDetails.duration"
                type="number"
                label="Est. Duration (hours)"
                style="width: 170px;"
              ></bs-float-input>

              </div>
            </div>
            <textarea v-model="taskDetails.description"
                  class="form-control"
                  placeholder="Description"
                  style="height: 100px"
                ></textarea>
                        <div class="row g-2">
              <div class="col-md">

            <label>Start Date</label>
            <datepicker v-model="taskDetails.start"/>
              </div>
              <div class="col-md">

            <label>End Date</label>
            <datepicker v-model="taskDetails.end"/>
              </div>
                        </div>
            <bs-button @click="updateDetails" style="margin-top: 10px;">Save</bs-button>
          </div>
        </div>
        <div class="col-12">
          <div class="p-3 border bg-light">
            <nav
              class="navbar navbar-expand-lg navbar-light bg-light"
              id="navbar-example"
            >
              <ul class="nav nav-tabs">
                <li class="nav-item">
                  <a
                    :class="{
                      'nav-link': true,
                      active: currentTab === 'notes',
                    }"
                    :aria-current="currentTab === 'notes' ? 'page' : ''"
                    href="#"
                    @click.prevent="showTab('notes')"
                    >Notes</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    :class="{ 'nav-link': true, active: currentTab === 'time' }"
                    :aria-current="currentTab === 'time' ? 'page' : ''"
                    href="#"
                    @click.prevent="showTab('time')"
                    >Time keeping</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    :class="{ 'nav-link': true, active: currentTab === 'log' }"
                    :aria-current="currentTab === 'log' ? 'page' : ''"
                    href="#"
                    @click.prevent="showTab('log')"
                    >Log</a
                  >
                </li>
              </ul>
            </nav>
            <div
              data-bs-spy="scroll"
              data-bs-target="#navbar-example"
              data-bs-offset="0"
              class="scrollspy-example"
              tabindex="0"
            >
              <div v-if="currentTab === 'notes'">
              <ul>
                <li v-for="(note, key) in task.notes" :key="key">
                  <p>{{ new Date(note.created).toLocaleString('en-US', {
      day: 'numeric', // numeric, 2-digit
      year: 'numeric', // numeric, 2-digit
      month: 'long', // numeric, 2-digit, long, short, narrow
      hour: 'numeric', // numeric, 2-digit
      minute: 'numeric', // numeric, 2-digit
  }) }}</p>
                  <div style="white-space: pre-line">{{ note.note}}</div>
                  <hr />
                </li>
              </ul>
              </div>
                            <div v-if="currentTab === 'time'">
                                Total Time: {{ totalTime }}  
              <ul>
                <li v-for="(log, key) in task.timelog" :key="key">
                  <p>{{ new Date(log.start).toLocaleString('en-US', {
      day: 'numeric', // numeric, 2-digit
      year: 'numeric', // numeric, 2-digit
      month: 'long', // numeric, 2-digit, long, short, narrow
      hour: 'numeric', // numeric, 2-digit
      minute: 'numeric', // numeric, 2-digit
      second: 'numeric', // numeric, 2-digit
  }) }}
   - 
{{ new Date(log.end).toLocaleString('en-US', {
      day: 'numeric', // numeric, 2-digit
      year: 'numeric', // numeric, 2-digit
      month: 'long', // numeric, 2-digit, long, short, narrow
      hour: 'numeric', // numeric, 2-digit
      minute: 'numeric', // numeric, 2-digit
      second: 'numeric', // numeric, 2-digit
  }) }}
   ({{ getAge((new Date(log.start)),(new Date(log.end))) }})
   
   
  </p>
                </li>
              </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </bs-modal>
</template>

<script>
import { ref,computed,reactive } from "vue";
import  TaskHelper  from '../../hooks/taskhelper.js';
import datepicker from 'vue3-datepicker';
import { useStore } from 'vuex';

export default {
  props: ["showModal", "task"],
  emits: ["modalMounted"],
  components: {datepicker},
  setup(props, context) {
    const store = useStore();
    var settingsModal = null;
    const currentTab = ref("notes");
    const taskDetails = reactive({
        id: props.task.id,
        title: props.task.title,
        description: props.task.description,
        duration: props.task.duration,
        start: new Date(props.task.start),
        end: new Date(props.task.end),
    });

    const totalTime = computed(()=>{
        var totalTime = 0;
        for(const log in props.task.timelog){
            totalTime += (
                (Math.floor(new Date(props.task.timelog[log].end))/1000) - (Math.floor(new Date(props.task.timelog[log].start))/1000));
        }
        var hours = Math.floor(totalTime / 3600);
        totalTime -= (3600 * hours);
        var minutes = Math.floor(totalTime / 60);
        totalTime -= (60 * minutes);
        
        return ""+hours + " hour" + (hours!=1?"s":"")+" "+minutes + "minute" + (minutes!=1?"s":"");

    });

    function getModal(modal) {
      context.emit("modalMounted", modal);
      settingsModal = modal;
    }

    function showTab(tab) {
      currentTab.value = tab;
    }

    function updateDetails(){
      if(!taskDetails.description){ 
        taskDetails.description = '';
      }
      if(!taskDetails.duration){ 
        taskDetails.duration = '';
      }
      if(!taskDetails.start){ 
        taskDetails.start = '';
      }
      if(!taskDetails.end){ 
        taskDetails.end = '';
      }
      store.dispatch('task/updateTask',Object.assign({},taskDetails));
      settingsModal.hide();
    }

    function getAge(a,b){
        return TaskHelper.getAge(a,b);
    }
    return { getModal, showTab, currentTab,getAge,totalTime,taskDetails,updateDetails };
  },
};
</script>

<style scoped>
body {
  position: relative;
}
.scrollspy-example{position:relative;height:200px;margin-top:.5rem;overflow:auto}.scrollspy-example-2{position:relative;height:350px;overflow:auto}
</style>
