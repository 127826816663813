<template><main>
  
<side-navbar @selectionChanged="navChanged" :windowsMinimised="windowsMinimised" @maximise="listMaximised"></side-navbar>
<div class="b-example-divider" style="height: 100%;" ></div>

  <div class="d-flex flex-column flex-shrink-1 p-3 bg-light"  style="height:100%;">
<div class="container">
  <div class="container">
  <div class="row">
     <transition name="taskList"> 
    <div class="col" v-if="!windowsMinimised['todo']">
    <task-list  taskType="todo" @minimise="minimise('todo')"></task-list>
    </div>
     </transition>
     <transition name="taskList"> 
    <div class="col"  v-if="!windowsMinimised['inbox']">
    <task-list taskType="inbox" :projectId="currentView.projectId" @minimise="minimise('inbox')"></task-list>
    </div>
     </transition>
     <transition name="taskList"> 
    <div v-if="currentView.projectId != '' && !windowsMinimised['project']" class="col">
    <task-list taskType="project" :projectId="currentView.projectId" @minimise="minimise('project')"></task-list>
    </div>
     </transition>
     <transition name="taskList"> 
    <div v-if="!windowsMinimised['followup']" class="col">
    <task-list taskType="followup" :projectId="currentView.projectId" @minimise="minimise('followup')"></task-list>
    </div>
     </transition>
     <transition name="taskList"> 
    <div v-if="!windowsMinimised['snoozed']" class="col">
    <task-list taskType="snoozed" :projectId="currentView.projectId" @minimise="minimise('snoozed')"></task-list>
    </div>
     </transition>
  </div>
</div>
</div>
  </div></main>
</template>

<script>
import SideNavbar from '../components/SideNavbar/SideNavbar.vue';

//import axios from "axios";
import TaskList from "../components/TaskList.vue";

import {reactive,ref} from 'vue';

export default {
  components: {  TaskList,SideNavbar },
  setup() {
    const windowsMinimised = ref([]);
    windowsMinimised.value['snoozed']=true;
    windowsMinimised.value['followup']=true;

    const currentView = reactive({contextId: '', projectId: '' });

    function navChanged(userSelection){
      currentView.contextId = userSelection.id;
      currentView.projectId = userSelection.projectId;
    }

    function minimise(list){
      windowsMinimised.value[list]=true;
    }

    function listMaximised(list){
      windowsMinimised.value[list]=false;
    }

    return {navChanged,minimise,listMaximised,currentView,windowsMinimised};

  },
};
</script>
<style  >
.navbar{
  margin-bottom: 0px;
  
}


.taskList-enter-from,
.taskList-leave-to
{
  opacity: 0;
  transform: translate(-300px,300px);
  

}

.taskList-enter-active{
  transition: all .3s ease-out;
}

.taskList-leave-active{
  transition: all .3s ease-in;
}

.taskList-enter-to,
.taskList-leave-from
{
  opacity: 1;
  transform: scale(1) translateX(0px,0px);
}
      

</style>