import { auth } from './firebase.js'


import SignupPage from './pages/auth/SignupPage.vue';
import AuthPage from './pages/auth/AuthPage.vue';
import ProjectPage from './pages/ProjectPage.vue';
import HomePage from './pages/HomePage.vue';
import EmailVerify from './pages/auth/EmailNotVerified.vue';
import TestPage from './pages/Test.vue';

//import store from './store/index.js';

import { createRouter, createWebHistory } from 'vue-router';



const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', redirect: '/project' },
    {
        name: 'project',
        path: '/project',
        meta: {requiresAuth: true},
        component: ProjectPage,
      },
      {
        name: 'auth',
        path: '/auth',
        meta: {requiresAuth: false,requiresUnauth: true},
        component: AuthPage,
      },
      {
        name: 'home',
        path: '/home',
        meta: {requiresAuth: false},
        component: HomePage,
      },
      {
        name: 'test',
        path: '/test',
        meta: {requiresAuth: false},
        component: TestPage,
      },
      {
        name: 'verifyEmail',
        path: '/verifyemail',
        meta: {requiresAuth:  false},
        component: EmailVerify,
      },
      {
        name: 'signup',
        path: '/signup',
        meta: {requiresAuth: false,requiresUnauth: true},
        component: SignupPage,
      },
  //    { path: '/:notfound(.*)', component: NotFound }
  ],
  linkActiveClass: 'active',
  scrollBehavior(_, _2, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { left: 0, top: 0 };
  }
});
router.beforeEach(async function(to,from,next){
  const currentUser = auth.currentUser;
  if(to.name == "verifyEmail" && !currentUser){
    next('/auth');
    return;
  }
  if(to.name == "verifyEmail" && currentUser.emailVerified){
    next('/project');
    return;
  }

if(to.meta.requiresAuth && !currentUser){
    next('/auth');
  }else if(to.meta.requiresUnauth && currentUser){
    next('/project?auto=1');
  }else{
    if(to.meta.requiresAuth && !currentUser.emailVerified){
      next('/verifyemail')
    }else{
  

    next();
    }
  } 
});

export default router;