<template>
  <bs-modal
    :showModal="AddModalVisible"
    @modalClosed="AddModalVisible = false"
    @saveModal="addContextorProject"
    :title="
      AddModalMode === 'project' ? 'Add a new Project' : 'Add a new context'
    "
    :showSave="true"
    :closeText="'Cancel'"
  >
    <form>
      <bs-float-input
        v-model="newContext"
        type="text"
        id="newContext"
        placeholder="At the Office"
        :label="
          AddModalMode === 'project' ? 'New project title' : 'New context title'
        "
      ></bs-float-input>
      <label
        v-if="AddModalMode === 'context'"
        for="exampleColorInput"
        class="form-label"
        >Context Colour</label
      >
      <input
        v-if="AddModalMode === 'context'"
        type="color"
        class="form-control form-control-color"
        id="exampleColorInput"
        v-model="newContextColour"
        title="Choose your color"
      />
    </form>
  </bs-modal>
  <div
    class="d-flex flex-column flex-shrink-0 p-3 text-white bg-dark"
    style="width: 280px;"
  >
    <a
      href="/"
      class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none"
    >
      <svg class="bi me-2" width="40" height="32">
        <use xlink:href="#bootstrap" />
      </svg>
      <span class="fs-2" style="text-align: center">Swift2do</span>
    </a>
    <hr />
    <form @submit.prevent="quickAdd">
      <div style="padding:10px; margin: 10px">
        <input
          type="text"
          class="form-control"
          id="exampleFormControlInput1"
          placeholder="New task"
          v-model.trim="newTask"
        />
        <bs-button style="margin-top: 10px;" @click="quickAdd"
          >Quick Add to In-tray</bs-button
        >
      </div>
    </form>
    <hr />
    <p class="fs-5">
      Category
    </p>
    <ul class="nav nav-pills flex-column ">
      <li
        v-for="sw_context in contextList"
        class="nav-item"
        :key="sw_context.id"
      >
        <a
          href="#"
          :class="{
            'nav-link': true,
            'text-white': sw_context.id != userSelection.contextId,
            'text-warning': sw_context.id === userSelection.contextId,
          }"
          aria-current="page"
          @click.prevent="selectContext(sw_context.id)"
        >
          <bs-icon
            :image="
              sw_context.id != userSelection.contextId
                ? 'plus-square'
                : 'dash-square'
            "
          />
          <bs-icon
            style="margin-left: 5px"
            image="square-fill"
            :colour="sw_context.colour"
          />
          {{ sw_context.title }}
        </a>
        <project-list
          @projectChanged="projectChanged"
          v-show="sw_context.id === userSelection.contextId"
          :contextID="sw_context.id"
          style="margin-left: -10px"
        ></project-list>
        <div
          style="padding-left: 40px"
          v-if="sw_context.id === userSelection.contextId"
        >
          <button
            type="button"
            class="btn btn-primary btn-sm"
            @click="showAddModal('project')"
          >
            Add Project
          </button>
        </div>
      </li>
    </ul>
    <button
      style="margin-top:20px"
      type="button"
      class="btn btn-primary btn-sm"
      @click="showAddModal('context')"
    >
      Add category
    </button>
    <hr />
    <transition name="taskListMin">
    <bs-hf-card-min v-if="windowsMinimised['todo']" title="To-do List" headerImage="clipboard" style="color: black;margin-bottom: 10px;" showMaximise="true" @maximise="$emit('maximise','todo')"></bs-hf-card-min>
    </transition>
    <transition name="taskListMin">
    <bs-hf-card-min v-if="windowsMinimised['inbox']" title="In Tray" headerImage="inboxes-fill" style="color: black;margin-bottom: 10px;" showMaximise="true" @maximise="$emit('maximise','inbox')"></bs-hf-card-min>
    </transition>
    <transition name="taskListMin">
    <bs-hf-card-min v-if="windowsMinimised['project']" :title="selectedProjectName" headerImage="folder" style="color: black;margin-bottom: 10px;" showMaximise="true" @maximise="$emit('maximise','project')" ></bs-hf-card-min>
    </transition>
    <transition name="taskListMin">
    <bs-hf-card-min v-if="windowsMinimised['followup']" title="Followup" headerImage="chat-quote" style="color: black;margin-bottom: 10px;" showMaximise="true" @maximise="$emit('maximise','followup')" ></bs-hf-card-min>
    </transition>
    <transition name="taskListMin">
    <bs-hf-card-min v-if="windowsMinimised['snoozed']" title="Snoozed" headerImage="alarm" style="color: black;margin-bottom: 10px;" showMaximise="true" @maximise="$emit('maximise','snoozed')" ></bs-hf-card-min>
    </transition>
    <hr />
    
    <ul class="nav nav-pills flex-column ">
      <li>
        <a href="#" class="nav-link text-white" @click.prevent="logout">
          <svg class="bi me-2" width="16" height="16">
            <use xlink:href="#grid" />
          </svg>
          logout
        </a>
      </li>
      <hr />
      <li>Signed in as: {{ userName }}</li>
      <li>{{ uid }}</li>
      <li>ver: 0.01&alpha;</li>
    </ul>
  </div>
</template>

<script>
import { ref, computed, reactive } from "vue";
import { useStore } from "vuex";
import ProjectList from "./ProjectList.vue";
import BsHfCardMin from '../ui/bootstrap/BsHfCardMin.vue';

export default {
  components: { ProjectList, BsHfCardMin },
  emits: ["selectionChanged","minimise",'maximise'],
  props: ["windowsMinimised"],
  setup(props, context) {
    const store = useStore();

    const newContext = ref("");
    const newContextColour = ref("#563d7c");
    const newProject = ref("");
    const newTask = ref("");
    const AddModalVisible = ref(false);
    const AddModalMode = ref(null);

    var contextProjectSelection = [];

    const userSelection = reactive({
      contextId: "",
      projectId: "",
    });

    const uid = computed(()=>{
      return store.getters['auth/getUser'].uid;
    })

    const selectedProjectName = computed(()=>{
        return store.getters['task/projects'].filter(project => {return project.id === userSelection.projectId})[0].title;
    });

    const contextList = computed(() => {
      return store.getters["task/contexts"];
    });

    function selectContext(contextId) {
      userSelection.contextId = contextId;
      const ctx = contextProjectSelection.filter((cps) => {
        return cps.contextId === contextId;
      });
      if (ctx.length === 0) {
        contextProjectSelection.push({ contextId: contextId, projectId: "" });
        userSelection.projectId = "";
      } else {
        userSelection.projectId = ctx[0].projectId;
      }
      context.emit("selectionChanged", userSelection);
    }

    //    function addTask() {
    //      context.emit("addTask", { title: this.$refs.newTask.value });
    //    }

    function projectChanged(project) {
      userSelection.projectId = project;
      const ctx = contextProjectSelection.filter((cps) => {
        return cps.contextId === userSelection.contextId;
      });
      if (ctx.length === 0) {
        contextProjectSelection.push({
          contextId: userSelection.contextId,
          projectId: project,
        });
      } else {
        ctx[0].projectId = project;
      }

      context.emit("selectionChanged", userSelection);
    }

    function quickAdd() {
      if (newTask.value === "") {
        return;
      }
      store.dispatch("task/addTask", {
        title: newTask.value,
        archive: false,
        parentID: null,
      });
      newTask.value = "";
    }

    function addContextorProject() {
      console.log("Adding new " + AddModalMode.value);
      if (newContext.value === "") {
        return;
      }
      if (AddModalMode.value === "context") {
        store.dispatch("task/addContext", {
          title: newContext.value,
          colour: newContextColour.value,
        });
      }
      if (AddModalMode.value === "project") {
        store.dispatch("task/addProject", {
          title: newContext.value,
          context: userSelection.contextId,
        });
      }

      newContext.value = "";
      newContextColour.value = "#563d7c";
      AddModalVisible.value = false;
      AddModalMode.value = null;
    }

    function showAddModal(mode) {
      AddModalVisible.value = true;
      AddModalMode.value = mode;
    }

    function logout() {
      store.dispatch("auth/logout");
    }

    const userName = computed(() => {
      return store.getters["user/name"] || "";
    });
    return {
      // addTask,
      userName,
      newTask,
      quickAdd,
      logout,
      showAddModal,
      newContext,
      newProject,
      newContextColour,
      contextList,
      selectContext,
      AddModalMode,
      AddModalVisible,
      addContextorProject,
      projectChanged,
      userSelection, //Object containing the users selectec contextId and projectId
      selectedProjectName,
      uid
    };
  },
};

/* global bootstrap: false */
(function() {
  "use strict";
  var tooltipTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="tooltip"]')
  );
  tooltipTriggerList.forEach(function(tooltipTriggerEl) {
    new bootstrap.Tooltip(tooltipTriggerEl);
  });
})();
</script>

<style>


.minCard {

    padding: 0.5rem 1rem;
    margin-bottom: 0;
    background-color: #DDDDDD;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    color: black;

}






.taskListMin-enter-from,
.taskListMin-leave-to
{
  opacity: 0;
  transform: scale(0);
  

}

.taskListMin-enter-active{
  transition: all .3s ease-out;
}

.taskListMin-leave-active{
  transition: all .3s ease-in;
}

.taskListMin-enter-to,
.taskListMin-leave-from
{
  opacity: 1;
  transform: scale(1);
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}
body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}

main {
  display: flex;
  flex-wrap: nowrap;
  height: 100vh;
  height: -webkit-fill-available;
  max-height: 100vh;
  overflow-x: auto;
  overflow-y: hidden;
}

.b-example-divider {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.1);
  border: solid rgba(0, 0, 0, 0.15);
  border-width: 1px 0;
  box-shadow: inset 0 0.5em 1.5em rgba(0, 0, 0, 0.1),
    inset 0 0.125em 0.5em rgba(0, 0, 0, 0.15);
}

.bi {
  vertical-align: -0.125em;
  pointer-events: none;
  fill: currentColor;
}

.dropdown-toggle {
  outline: 0;
}

.nav-flush .nav-link {
  border-radius: 0;
}

.btn-toggle {
  display: inline-flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.65);
  background-color: transparent;
  border: 0;
}
.btn-toggle:hover,
.btn-toggle:focus {
  color: rgba(0, 0, 0, 0.85);
  background-color: #d2f4ea;
}

.btn-toggle::before {
  width: 1.25em;
  line-height: 0;
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
  transition: transform 0.35s ease;
  transform-origin: 0.5em 50%;
}

.btn-toggle[aria-expanded="true"] {
  color: rgba(0, 0, 0, 0.85);
}
.btn-toggle[aria-expanded="true"]::before {
  transform: rotate(90deg);
}

.btn-toggle-nav a {
  display: inline-flex;
  padding: 0.1875rem 0.5rem;
  margin-top: 0.125rem;
  margin-left: 1.25rem;
  text-decoration: none;
}
.btn-toggle-nav a:hover,
.btn-toggle-nav a:focus {
  background-color: #d2f4ea;
}

.scrollarea {
  overflow-y: auto;
}

.fw-semibold {
  font-weight: 600;
}
.lh-tight {
  line-height: 1.25;
}
</style>
