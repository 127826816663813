<template>
  <div>
    <ul>
      <li v-for="project in getContextProjects" :key="project.id">
        <input
          class="form-check-input me-1"
          type="radio"
          :name="'projects-' + contextID"
          :value="project.id"
          :id="project.id"
          aria-label="..."
          v-model="projectId"
          @change="projectChanged"
          
        /><label :for="project.id">{{ project.title }}</label>
      </li>
    </ul>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed,ref } from "vue";

export default {
  props: ["contextID"],
  setup(props,context) {
      const projectId = ref('');

    const store = useStore();

    const getContextProjects = computed(() => {
      const projects = store.getters["task/projects"];
      return projects.filter((project) => {
        return project.context === props.contextID;
      });
    });

    function projectChanged(){
        context.emit('projectChanged',projectId.value);

    }

    return { getContextProjects,projectChanged,projectId };
  },
};
</script>
<style scoped>
ul {
  list-style-type: none; /* Remove bullets */
}
li {
    margin-bottom: 10px;

}
</style>
