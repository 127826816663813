const firebaseConfig = {
    apiKey: "AIzaSyAOJJXaTCfmgHX4j20Z2AN8wFldD8B10Bo",
    authDomain: "swift2do.firebaseapp.com",
    databaseURL: "https://swift2do-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "swift2do",
    storageBucket: "swift2do.appspot.com",
    messagingSenderId: "185429062554",
    appId: "1:185429062554:web:e770ed135c463ff1988023"
  };

  export default firebaseConfig;