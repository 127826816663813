<template>
  <div class="card">
    <div class="card-header">
      <bs-icon v-if="headerImage" :image="headerImage"></bs-icon>
      {{ title }}
      <a href="#" v-if="showMinimise" @click.prevent="$emit('minimise');" class="bsMinimise">
      <bs-icon image="file-minus" width="20" height="20"></bs-icon>
      </a>
      <a href="#" v-if="showMaximise" @click.prevent="$emit('maximise');" class="bsMinimise">
      <bs-icon image="file" width="20" height="20"></bs-icon>
      </a>
    </div>
  </div>
</template>

<script>
export default {
    emits: ['minimise','maximise'],

  props: ["title","headerImage","showMinimise","showMaximise"],
};
</script>

<style  scoped>

.bsMinimise{
 position: absolute;
  right: 10px;
}



</style>
