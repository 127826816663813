<template>
  <div class="modal fade" ref="exampleModal" tabindex="-1" aria-hidden="true">
    <div :class="dialogClass">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
          <button type="button" class="btn-close" @click="closeModal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <slot></slot>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="closeModal">{{ closeText || 'Close'}}</button>
          <button v-if="showSave" type="button" class="btn btn-primary" @click="$emit('saveModal')">Save changes</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Modal } from 'bootstrap'
export default {
  props: ['showModal','title','showSave','closeText','modalSize'], //Modal Size is modal-sm modal-lg modal-xl
  emits: ['modalClosed','saveModal','modalMounted'],
  watch: {
      showModal(newVal){
          if(newVal){
                  this.modal.show();
              }else{
                  this.modal.hide();
          }
      }
  },
  methods: {
      closeModal(){
            this.modal.hide();
          this.$emit('modalClosed');
      }
  },
  computed: {
    dialogClass(){
      var cls = {
        'modal-dialog': true,
      };
      if(this.modalSize && this.modalSize != "")
      cls[this.modalSize] = true;
      return cls;
    }
  },
  data: () => ({
    modal: null
  }),
  mounted() {
    this.modal = new Modal(this.$refs.exampleModal)
    this.$emit('modalMounted',this.modal);
  }
};
</script>