<template>
<task-settings-modal
    @modalMounted="getSettingsModal"
    :showModal="showSettingsModal"
    :task="task"
    @modalClosed="closeSettingsModal"


></task-settings-modal>
  <task-action-modal
    ref="actionModel"
    @modalMounted="getActionModal"
    :showModal="showActionModal"
    :task="task"
    @modalClosed="closeActionModal"
  ></task-action-modal>
  <div class="taskTile">
    <div class="taskTile-header">
      <span class="d-inline-block" tabindex="0">
        <a
          href="#"
          data-bs-trigger="hover"
          data-bs-container="body"
          data-bs-toggle="popover"
          data-bs-placement="top"
          data-bs-content="Change this tasks category"
        >
          <bs-icon
            :image="contextColour == '' ? 'app' : 'square-fill'"
            style="margin-right:5px;"
            :colour="contextColour"
        /></a>
      </span>
      <strong class="me-auto iconBar">
        <ul class="iconBar">
          <li v-for="(icon, key) in getHeaderIcons" :key="key">
            <a
              href="#"
              @click.prevent="icon.click"
              data-bs-trigger="hover"
              data-bs-container="body"
              data-bs-toggle="popover"
              data-bs-placement="top"
              :data-bs-content="icon.tooltip"
              ><bs-icon
                class="taskIcon"
                :width="iconSize"
                :height="iconSize"
                :image="icon.image"
                :colour="icon.colour ? icon.colour : '#000000'"
            /></a>
          </li>
        </ul>
      </strong>
      <small>{{ getTaskAge() }} ago</small>
      <a
        href="#"
        @click.prevent="doneTask"
        data-bs-trigger="hover"
        data-bs-container="body"
        data-bs-toggle="popover"
        data-bs-placement="top"
        data-bs-content="Mark the task as done and archive it so its out of the way"
        ><bs-icon image="check-lg" color="green" style="margin-left:5px;"
      /></a>
    </div>
    <div class="taskTile-body">
      {{ task.title }}
      <div class="snooze-text" v-if="snoozeText.length > 0">
        {{ snoozeText }}
      </div>
      <div class="followup-text" v-if="followupText.length > 0">
        {{ followupText }}
      </div>
    </div>
  </div>
</template>

<script>
import BsIcon from "./ui/bootstrap/BsIcon.vue";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { Popover } from "bootstrap/dist/js/bootstrap.esm.min.js";
import TaskActionModal from "./task-modals/TaskActionModal.vue";
import TaskHelper from "../hooks/taskhelper.js";
import TaskSettingsModal from './task-modals/TaskSettingsModal.vue';

export default {
  emits: ["doneTask"],
  components: { BsIcon, TaskActionModal, TaskSettingsModal },
  props: ["task", "activeProject", "parentList"],
  setup(props, context) {
    const store = useStore();
    const showActionModal = ref(false);
    const showSettingsModal = ref(false);
    const actionModal = ref(null);
    const settingsModal = ref(null);

    const iconSize = computed(() => {
      return 16;
    });

    const snoozeText = computed(() => {
      return TaskHelper.snoozeText(props.task);
    });

    const followupText = computed(() => {
      return TaskHelper.followupText(props.task);
    });

    function closeActionModal() {}
    function closeSettingsModal() {}

    function getActionModal(modal) {
      actionModal.value = modal;
    }

    function getSettingsModal(modal) {
      settingsModal.value = modal;
    }

    function actionTask() {
      showActionModal.value = true;
      actionModal.value.show();
    }

    function settingsTask() {
      showSettingsModal.value = true;
      settingsModal.value.show();
    }

    const stopwatchStarted = computed(() => {
      return props.task.started;
    });

    const getHeaderIcons = computed(() => {
      const icons = [];
      if (props.parentList != "todo") {
        icons.push({
          image: "clipboard",
          click: addTaskToTodo,
          tooltip: "Add this task to the to-do list",
        });
      } else {
        icons.push({
          image: "inboxes-fill",
          click: removeTaskToTodo,
          tooltip:
            "Remove this task from the to-do list and put it back in your in-tray",
        });
      }
      if (
        props.activeProject &&
        props.activeProject != "" &&
        props.parentList === "inbox"
      ) {
        icons.push({
          image: "folder-plus",
          click: addTaskToProject,
          tooltip: "Add this task to " + selectedProjectName.value + " project",
        });
      }
      if (
        props.activeProject &&
        props.activeProject != "" &&
        props.parentList === "project"
      ) {
        icons.push({
          image: "folder-minus",
          click: removeTaskFromProject,
          tooltip:
            "Remove this task from " + selectedProjectName.value + " project",
        });
      }
      icons.push({
        image: "lightning",
        click: actionTask,
        tooltip:
          "Action this task eg. snooze it, or delegate it to a person or place",
      });
      icons.push({
        image: "gear",
        click: settingsTask,
        tooltip:
          "Change the settings eg. due date, reminder alerts, recurring schedules etc",
      });
      icons.push({
        image: "stopwatch",
        colour: stopwatchStarted.value ? "green" : "red",
        click: toggleStopWatch,
        tooltip:
          "Start/Stop the timer on this task to track how much time you spend on it",
      });
      return icons;
    });

    const selectedProjectName = computed(() => {
      return store.getters["task/projects"].filter((project) => {
        return project.id === props.activeProject;
      })[0].title;
    });

    function toggleStopWatch() {
      if (props.task.started && props.task.started != "") {
        store.dispatch("task/stopTask", {
          taskId: props.task.id,
        });
      } else {
        store.dispatch("task/startTask", {
          taskId: props.task.id,
        });
      }
    }

    function comingSoon() {
      alert("Functionality coming soon...");
    }

    const contextColour = computed(() => {
      if (!props.task.contextId || props.task.contextId === "") {
        return "";
      }
      return store.getters["task/contexts"].filter((context) => {
        return context.id === props.task.contextId;
      })[0].colour;
    });

    function doneTask() {
      console.log("task done");
      context.emit("doneTask", props.task.id);
    }

    function addTaskToProject() {
      console.log("add Task " + props.task.id + " to project");
      store.dispatch("task/addTaskToProject", {
        taskId: props.task.id,
        projectId: props.activeProject,
      });
    }

    function addTaskToTodo() {
      store.dispatch("task/addTaskToTodo", {
        taskId: props.task.id,
      });
    }

    function removeTaskToTodo() {
      store.dispatch("task/removeTaskToTodo", {
        taskId: props.task.id,
      });
    }

    function removeTaskFromProject() {
      store.dispatch("task/removeTaskFromProject", {
        taskId: props.task.id,
      });
    }
    function getTaskAge() {
      return TaskHelper.getAge(new Date(props.task.created));
    }

    return {
      iconSize,
      contextColour,
      getHeaderIcons,
      doneTask,
      getTaskAge,
      addTaskToProject,
      removeTaskFromProject,
      addTaskToTodo,
      removeTaskToTodo,
      comingSoon,
      actionTask,
      settingsTask,
      selectedProjectName,
      showActionModal,
      showSettingsModal,
      closeActionModal,
      closeSettingsModal,
      getActionModal,
      getSettingsModal,
      snoozeText,
      followupText,
      stopwatchStarted,
      toggleStopWatch,
    };
  },
  mounted() {
    //inti Popover
    Array.from(
      document.querySelectorAll('a[data-bs-toggle="popover"]')
    ).forEach((popoverNode) => new Popover(popoverNode));
  },
  beforeUnmount() {
    var element = document.querySelectorAll('div[data-popper-placement="top"]');
    Array.prototype.forEach.call(element, function(node) {
      node.parentNode.removeChild(node);
    });
  },
};
</script>

<style scoped>
.snooze-text {
  color: brown;
  font-weight: bold;
}

.followup-text {
  color: cornflowerblue;
  font-weight: bold;
}

.taskTile {
  width: 250px;
  max-width: 100%;
  font-size: 0.870rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  margin-bottom: 15px;
}
.taskTile-container {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.taskTile-container > :not(:last-child) {
  margin-bottom: 0.75rem;
}
.taskTile-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.taskTile-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}
.taskTile-body {
  padding: 0.75rem;
  word-wrap: break-word;
}

.taskIcon {
  margin-left: 6px;
}

.iconBar ul > li {
  display: inline-block;
  /* You can also add some margins here to make it look prettier */
  zoom: 1;
  *display: inline;
  /* this fix is needed for IE7- */
  padding: 0;
  margin: 0;
}

.iconBar ul {
  list-style-type: none;
  display: contents;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
</style>
