import getters from './getters.js'
import actions from './actions.js'
import mutations from './mutations.js'

export default{

    namespaced: true,
    state(){
        return {
            user: null,
            hasLoggedIn: false, //User has logged in at least once
            didLogout: false,
            emailVerified: false,
        };
    },
    getters,
    mutations,
    actions
}