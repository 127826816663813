

export default {

    isAuthenticated(state){
        if(state.user){
            return true;
        }else{
            return false;
        }
    },
    getUser(state) {
        return state.user;
    },
    didLogout(state){
        return state.didLogout;

    },
    hasLoggedIn(state){
        return state.hasLoggedIn;
    },
    emailVerified(state){
        console.log("Email verfied = "+state.emailVerified)
        return state.emailVerified;
    }


}