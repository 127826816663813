import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import 'firebase/app-check';

const firebaseConfig = {
    apiKey: "AIzaSyAOJJXaTCfmgHX4j20Z2AN8wFldD8B10Bo",
    authDomain: "swift2do.firebaseapp.com",
    databaseURL: "https://swift2do-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "swift2do",
    storageBucket: "swift2do.appspot.com",
    messagingSenderId: "185429062554",
    appId: "1:185429062554:web:e770ed135c463ff1988023"
  };

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
    const appCheck = firebase.appCheck();
// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
  appCheck.activate('6LdfUF8bAAAAAOOwW4iFGRqep7aJabKbTCU5bI8L');
  } else {
    firebase.app(); // if already initialized, use that one
  }

  const database = firebase.database();
  const auth = firebase.auth();

  export {
      database,auth
  }