
import {createStore} from 'vuex';

import authStore from './auth/index.js';
import userStore from './user/index.js';
import taskStore from './task/index.js';

const store = createStore({
    modules: {
        auth: authStore,
        user: userStore,
        task: taskStore,
    },
    state(){
        return {};
    }

});

export default store;