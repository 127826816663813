<template>
  <button type="button" :class="cssClass">
    <slot></slot>
  </button>
</template>

<script>
import { computed } from "vue";
export default {
  props: ["buttonOutline", "buttonStyle", "outline"],
  setup(props) {
    const cssClass = computed(() => {
      var css = {
        btn: true,
      };
      var style =
        props.buttonStyle && props.buttonStyle != ""
          ? (style = props.buttonStyle)
          : "primary";
      css[
        "btn-" + (typeof props.outline != "undefined" ? "outline-" : "") + style
      ] = true;
      css["btn-outline" + style] = true;
      return css;
    });
    return { cssClass };
  },
};
</script>
