function isSnoozed(task){
  return task.snoozed && task.snoozed != "" && new Date(task.snoozed) > new Date();
}

function snoozeText(task){
  if(isSnoozed(task)){
    const snoozeDate = new Date(task.snoozed);
    return "snooze until "+snoozeDate.toLocaleString('en-US', {
      day: 'numeric', // numeric, 2-digit
      year: 'numeric', // numeric, 2-digit
      month: 'short', // numeric, 2-digit, long, short, narrow
      hour: 'numeric', // numeric, 2-digit
      minute: 'numeric', // numeric, 2-digit
  });
  }else{
    return "";
  }
}

function followupText(task){
  if(!isFollowup(task)){
    return "";
  }
  var text = "Follow up ";
  if(task.followupPerson){
    text += "with "+task.followupPerson;
  }
  if(task.followupPlace){
    text += " @"+task.followupPlace;
  }
  text += " ("+getAge(new Date(task.followupSet))+" ago)";
  return text;
}

function isFollowup(task){
  return (task.followupPerson && task.followupPerson != '') ||
  (task.followupPlace && task.followupPlace != '')
}

function getAge(date,nowDate='') {
  if(nowDate===''){
    nowDate = new Date();
  }
  var seconds = Math.floor((nowDate - date) / 1000);
  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " year"+(Math.floor(interval)===1?"":"s");
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " month"+(Math.floor(interval)===1?"":"s");
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " day"+(Math.floor(interval)===1?"":"s");
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hour"+(Math.floor(interval)===1?"":"s");
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minute"+(Math.floor(interval)===1?"":"s");
  }
  return Math.floor(seconds) + " second"+(Math.floor(interval)===1?"":"s");
}

export default {
      isSnoozed,
      snoozeText,
      isFollowup,
      followupText,
      getAge


}