<template>
 <nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container-fluid">
    <a class="navbar-brand" href="#"><img src="/s.png"/> Swift2do</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <router-link class="nav-link" to="/home">Home</router-link>
        </li>
        <li v-if="!isAuthenticated" class="nav-item">
          <router-link class="nav-link" to="/auth">Login</router-link>
        </li>
        <li v-if="!isAuthenticated" class="nav-item">
          <router-link class="nav-link" to="/signup">Signup</router-link>
        </li>
        <li v-if="isAuthenticated && false" class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Dropdown
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><a class="dropdown-item" href="#">Action</a></li>
            <li><a class="dropdown-item" href="#">Another action</a></li>
            <li><hr class="dropdown-divider"></li>
            <li><a class="dropdown-item" href="#">Something else here</a></li>
          </ul>
        </li>
        <li v-if="isAuthenticated && false" class="nav-item">
          <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
        </li>
        <li v-if="isAuthenticated" class="nav-item">
          <a class="nav-link" href="#" @click.prevent="logout">Logout</a>
        </li>
      </ul>
    </div>
  </div>
</nav>

</template>

<script>
import { computed } from '@vue/runtime-core';
import { useStore } from 'vuex';

export default {
    setup() {
          const store = useStore(); 

        function logout(){
          store.dispatch('auth/logout');
          console.log("Logging out..");

        }
        const isAuthenticated = computed(()=>{return store.getters['auth/isAuthenticated'];});
        return { logout,isAuthenticated };
    },
}
</script>

<style>

.navbar{
  margin-bottom: 60px;
  background-color: brown;
}

</style>
